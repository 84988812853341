import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/pollution",
    method: "Get",
    params: params,
  });
}

// 增加
export function addPollution(requestBody) {
  return request({
    url: "/pollution",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editPollution(requestBody) {
  return request({
    url: "/pollution",
    method: "Patch",
    data: requestBody,
  });
}

// 删除
export function deletePollution(params) {
  return request({
    url: "/pollution",
    method: "Delete",
    params: params,
  });
}

// 获取Pollution option
export function getPollutionOptions(params) {
  return request({
    url: "/pollution/option",
    method: "Get",
    params: params,
  });
}
