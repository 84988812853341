import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/check/detail",
    method: "Get",
    params: params,
  });
}
