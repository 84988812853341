import request from "@/utils/request";

// 获取统计图数据
export function getChartsData(params) {
  return request({
    url: "/check/charts",
    method: "Get",
    params: params,
  });
}
